<template>
    <div class="common-wrapper">
    <el-select v-model="valueMeta" collapse-tags placeholder="请选择" @visible-change="getDefault" @change="handleSelectConfirm">
      <div class="el-input" style="width:auto;margin:0 5%;min-width:90px;max-width:150px">
        <input type="text" placeholder="请输入" class="el-input__inner" v-model="dropDownValue" @keyup="dropDownSearch" style="height:28px;width:98%">
      </div>
      <el-option v-for="item in optionsMetaShow" :label="item.label" :key="item.value" :value="item.value"></el-option>
    </el-select>
  </div>

</template>
<script>
export default {
  props:['optionsMetaAll'],
    data(){
        return {
          dropDownValue:'',
          valueMeta:"",
          optionsMetaShow:[]
        }
    },
    created(){
      this.optionsMetaShow=this.optionsMetaAll
      if(this.optionsMetaAll[0]){
        this.valueMeta = this.optionsMetaAll[0].value
      }
    },
    methods:{
        dropDownSearch () {
          this.valueMeta = '';
          this.optionsMetaShow = this.optionsMetaAll.filter((item)=>{
            return item.label.includes(this.dropDownValue)
          });

          this.$nextTick(()=>{
            document.getElementsByClassName('el-scrollbar').forEach(element => {
              element.style.display = 'block'
            });
          })
          
        },
        getDefault(){
          this.optionsMetaShow=this.optionsMetaAll
          this.dropDownValue=""
        },
        handleSelectConfirm(){
          this.$emit('input',this.valueMeta)
        }
    }

    }
</script>

<style>
  
</style>
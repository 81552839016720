<template>
    <div class="agent-profot-detail fadeInRight">
        <div class="title-box clearfix">
            <span class="m-title">返利详情</span>
            <el-form inline ref="agentProfitDetailForm" v-model="agentProfitDetailData" class="right themed-form search-form" style="height: auto;line-height: 40px;">
                <el-form-item>
                    <el-input v-model="agentProfitDetailData.orderNum" placeholder="订单号" ></el-input>
                </el-form-item>
                <el-form-item>
                    <el-input v-model="agentProfitDetailData.mealName" placeholder="套餐名称" ></el-input>
                </el-form-item>
                 <el-form-item>
					<el-date-picker style="width: 126px;" placeholder="起始日期" v-model="agentProfitDetailData.startTime" type="date" value-format="yyyy-MM-dd"/>
				</el-form-item>
				<el-form-item>
					<span class="text-color">至</span>
				</el-form-item>
				<el-form-item>
					<el-date-picker style="width: 126px;" placeholder="结束日期" v-model="agentProfitDetailData.endTime" type="date" value-format="yyyy-MM-dd"/>
				</el-form-item>

                <el-form-item label="状态">
                    <search-select :optionsMetaAll="statusOperators" v-model="agentProfitDetailData.payStatus"></search-select>
                </el-form-item>
                <el-form-item>
					<el-button icon="el-icon-search" type="primary" class="themed-button" @click="onSearch">查询</el-button>
				</el-form-item>

                <el-form-item>
					<el-button icon="el-icon-download" class="themed-button" type="info" @click="exportExcel">导出Excel</el-button>
				</el-form-item>
            </el-form>
        </div>

        <div class="content-box">
            <el-table
                border
                stripe
                ref="table"
                :max-height="maxTableHeight"
                :data="tableData"
                tooltip-effect="dark"
                style="width: 100%"
                >
                <el-table-column
                    show-overflow-tooltip
                    prop="agentTreeName"
                    label="所属代理商"
                    min-width="120">
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="agentName"
                    label="所属总代理商"
                    min-width="120">
                </el-table-column>
                 <el-table-column
                    prop="orderNum"
                    label="订单号"
                    min-width="180">
                </el-table-column>
                <el-table-column
                    prop="iccidMark"
                    label="iccid"
                    min-width="180">
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="phone"
                    label="接入号"
                    min-width="120">
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="mealName"
                    label="套餐名称"
                    min-width="120">
                </el-table-column>
                 <el-table-column
                    prop="orderMoney"
                    label="订单金额"
                    min-width="100">
                </el-table-column>
                <el-table-column
                    prop="rebateRatio"
                    label="返现比例"
                    min-width="100">
                </el-table-column>
                <el-table-column
                    prop="primePrice"
                    label="成本价"
                    min-width="100">
                </el-table-column>

                <!-- <el-table-column
                    prop="orderCount"
                    label="订购数量"
                    min-width="100">
                </el-table-column> -->
                <el-table-column
                    label="退款金额"
                    min-width="100">
                    <template slot-scope="scope">
                        <span v-if="scope.row.refundFee !=''">{{parseFloat(scope.row.refundFee).toFixed(2)}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="支付状态"
                    min-width="100">
                    <template slot-scope="scope">
                        <span v-if="(scope.row.payStatus==1)||(scope.row.payStatus==2)">已支付</span>
                        <span v-else-if="scope.row.payStatus==4">已退款</span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="返利方式"
                    min-width="100">
                    <template slot-scope="scope">
                        <span v-if="scope.row.type==0">未设置返利模式</span>
                        <span v-else-if="scope.row.type==1">返利比模式</span>
                        <span v-else-if="scope.row.type==2">销售价模式</span>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="applyMoney"
                    label="返现金额"
                    min-width="100">
                </el-table-column>
                <el-table-column
                    prop="deApplyMoney"
                    label="应扣返现金额"
                    min-width="120">
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="createTime"
                    label="创建时间"
                    min-width="150">
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="refundTime"
                    label="退款时间"
                    min-width="150">
                </el-table-column>
            </el-table>

            <el-pagination :current-page.sync="page"  :page-sizes="[5,10,15,50,100,1000]" :page-size="pageSize" style="text-align: left;margin-top: 20px;"
			 background layout="total,sizes,prev, pager, next,jumper" :total="total" @size-change="handleSizeChange" @current-change="onPageChange"></el-pagination>
        </div>
    </div>
</template>
<script>
import {agentProfitDetailList,exportProfitDetail} from '@/api/order.js';
import resize from '@/mixins/resize.js'
import SearchSelect from '@/components/SearchSelect.vue'
export default {
    name:'agentProfitDetail',
    components:{SearchSelect},
    mixins:[resize],
    data(){
        return {
            agentProfitDetailData:{
                id:'',
                orderNum:'',
                mealName:'',
                startTime:'',
                endTime:'',
                payStatus:''
            },
            statusOperators:[
                {label:'全部',value:'0'},
                {label:'已支付',value:'1'},
                {label:'已退款',value:'2'}
            ],
            tableData:[],
            page:1,
            pageSize:15,
            total:0,
        }
    },
    created(){
        this.quaryParams = this.$route.query
        this.getAgentProfitDetailList()
    },
    methods:{
        getAgentProfitDetailList(){
            this.quaryParams.agentId= this.quaryParams.agentId==''?'undefined':this.quaryParams.agentId
            let params = {
                pageSize:this.pageSize,
                pageNo:this.page,
            }
            Object.assign(params,this.quaryParams)
            Object.assign(this.agentProfitDetailData,params)

            agentProfitDetailList(this.agentProfitDetailData).then(res=>{
                this.tableData = res.rows
                this.total = res.count
            })
        },
        handleSizeChange(size){
            this.pageSize = size
            this.getAgentProfitDetailList()
        },
        onPageChange(page){
            this.page=page
            this.getAgentProfitDetailList()
        },
        onSearch(){
            this.page=1
            this.getAgentProfitDetailList()
        },
        exportExcel(){
            exportProfitDetail(this.agentProfitDetailData).then(res=>{
					if (res.system_result_key == '0') {
						this.$alert('导出成功，请到导出记录中下载', '提示', {
						    confirmButtonText:'确定',
						}).then(()=>{
							this.$router.push('/tob/i_export_list')
						});
					}else{
						this.$message({
						    showClose: true,
						    message: res.system_result_message_key,
						    type: 'error'
						});
					}
				})
        }
    }
}
</script>
<style>
    .agent-profot-detail .el-form-item__label{
        vertical-align: top;
    }
</style>
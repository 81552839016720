import request from './request.js'
// 订单结算
export function orderAccountingList(params) {
    return request({
        url: 'order/orderaccounting/list.do?',
        method: 'post',
        data: params
    })
}

// 订单结算
export function orderAccountingRecord(params) {
    return request({
        url: 'order/orderaccounting/updateForm.do?',
        method: 'post',
        data: params
    })
}

// 确认付款
export function orderAccountingSureRecord(params) {
    return request({
        url: 'order/orderaccounting/updateStatus.do?',
        method: 'post',
        data: params
    })
}

// 导出文件
export function exportAccountOrder(params) {
    return request({
        url: 'order/orderaccounting/export2excelSync.do?',
        method: 'post',
        data: params
    })
}

// 后台订单
export function backgroundOrderList(params) {
    return request({
        url: 'order/carmealorder/list.do?',
        method: 'post',
        data: params
    })
}

// 退款操作
export function refundOrder(params) {
    return request({
        url: 'order/carmealorder/refund.do?',
        method: 'post',
        data: params
    })
}

// 导出后台订单
export function exportBackgroundOrder(params) {
    return request({
        url: 'order/carmealorder/export2excelSync.do?',
        method: 'post',
        data: params
    })
}

// 获取提现申请列表
export function withdrawalApplicationList(params) {
    return request({
        url: 'order/orderaccounting/list1.do?',
        method: 'post',
        data: params
    })
}

// 提现导出
export function exportWithdrawalOrder(params) {
    return request({
        url: 'order/orderaccounting/export2excelSync.do?',
        method: 'post',
        data: params
    })
}

// 代理商订单
// 获取运营商列表
export function agentOperatorList(params) {
    return request({
        url: '/system/cardOperator/getAccountListByAgent.do?',
        method: 'post',
        data: params
    })
}

// 导出代理商订单
export function exportAgentOrder(params) {
    return request({
        url: 'order/agentorder/export2excelSync.do?',
        method: 'post',
        data: params
    })
}

// 代理商订单列表
export function agentOrderList(params) {
    return request({
        url: 'order/agentorder/list.do?',
        method: 'post',
        data: params
    })
}

// 获取登录类型
export function loginType(params) {
    return request({
        url: 'pages/getLogin.do?',
        method: 'post',
        data: params
    })
}

// 退款操作
export function refundMoney(params) {
    return request({
        url: 'order/agentorder/refund.do?',
        method: 'post',
        data: params
    })
}

// 微信订单
// 获取开卡公司
export function cardCompanyList(params) {
    return request({
        url: '/system/cardOperator/getAccountListByAgent.do?',
        method: 'post',
        data: params
    })
}

// 微信订单列表
export function weChatOrderList(params) {
    return request({
        url: 'order/agentwechatorder/list1.do?',
        method: 'post',
        data: params
    })
}

// 导出
export function exportWeixinOrder(params) {
    return request({
        url: 'order/agentwechatorder/export2excel1Sync.do?',
        method: 'post',
        data: params
    })
}

export function exportWeixinOrderNew(params) {
    return request({
        url: 'order/agentwechatorder/exportAgentexcelSync.do?',
        method: 'post',
        data: params
    })
}

// 获取订单详情信息
export function weChatOrderDetailInfo(params) {
    return request({
        url: 'order/agentwechatorder/updateForm.do?',
        method: 'post',
        data: params
    })
}

// 微信订单退款
export function weChatOrderRefund(params) {
    return request({
        url: 'order/agentwechatorder/update4refund.do?',
        method: 'post',
        data: params
    })
}

// 微信退款订单

export function weChatRefundOrder(params) {
    return request({
        // url: 'order/agentwechatorder/list1.do?',
        url: 'order/agentwechatorder/list2.do?',
        method: 'post',
        data: params
    })
}

// 导出微信退款
export function exportWeixinRefundOrder(params) {
    return request({
        url: 'order/agentwechatorder/export2excelSync.do?',
        method: 'post',
        data: params
    })
}

// 发票列表
// 获取发票列表数据
export function billApplyList(params) {
    return request({
        url: 'billApply/list.do?',
        method: 'post',
        data:params
    })
}

// 导出发票列表
export function exportBillApply(params) {
    return request({
        url: 'billApply/exportSync.do?',
        method: 'post',
        data:params
    })
}

export function handleBill(params) {
    return request({
        url: 'billApply/bill.do',
        method: 'post',
        data:params
    })
}
// 作废发票

export function disableBill(params) {
    return request({
        url: '/billApply/disable.do',
        method: 'post',
        data:params
    })
}
// 返利列表/
// 获取返利列表
export function rebateList(params) {
    return request({
        url: 'agentApply/getProfit.do?',
        method: 'post',
        data:params
    })
}

// 导出返利列表
export function exportRebate(params) {
    return request({
        url: 'agentApply/exportProfitSync.do?',
        method: 'post',
        data:params
    })
}

// 导出返利详情
// export function exportRebateDetail(params) {
//     return request({
//         url: 'agentApply/exportTOCListSync.do',
//         method: 'post',
//         data:params
//     })
// }


// 返利提现
export function applyMoney(params) {
    return request({
        url: 'agentApply/agentApply.do?',
        method: 'post',
        data:params
    })
}

// 提现申请
// 获取提现申请列表
export function agentProfitList(params) {
    return request({
        url: 'agentApply/applyList.do?',
        method: 'post',
        data:params
    })
}

// 提现申请详情列表
export function agentProfitDetailList(params) {
    return request({
        url: 'agentApply/orderList.do?',
        method: 'post',
        data:params
    })
}

// 审批提现申请
export function agentProfitApplyCheck(params) {
    return request({
        url: 'agentApply/applyCheck.do?',
        method: 'post',
        data:params
    })
}

// 导出提现申请
export function exportAgentProfit(params) {
    return request({
        url: 'agentApply/exportTOCApplySync.do?',
        method: 'post',
        data:params
    })
}

// 返利详情导出
export function exportProfitDetail(params) {
    return request({
        url: 'agentApply/exportTOCListSync.do?',
        method: 'post',
        data:params
    })
}

// 单独界面
// 详情界面
export function orderDetailList(params) {
    return request({
        url: 'order/orderaccountingday/list.do?',
        method: 'post',
        data:params
    })
}

// 导出详情
export function exportOrderDetail(params) {
    return request({
        url: 'order/orderaccountingday/export2excelSync.do?',
        method: 'post',
        data:params
    })
}

// 代理商微信订单
export function agentwechatorder(params) {
    return request({
        url: 'order/agentwechatorder/list.do?',
        method: 'post',
        data:params
    })
}


// 订单列表

export function getAllOrderList(params) {
    return request({
        url: 'order/agentorder/getAllOrderList.do?',
        method: 'post',
        data:params
    })
}

// 导出所有订单
export function exportAllOrder(params) {
    return request({
        url: 'order/agentwechatorder/export2excel1Sync.do?',
        method: 'post',
        data:params
    })
}

// 后台提现审批
export function withdrawalApprovalList(params) {
    return request({
        url: 'agentApply/applyCheckList.do?',
        method: 'post',
        data:params
    })
}

export function checkWithdrawalApproval(params) {
    return request({
        url: 'agentApply/check.do?',
        method: 'post',
        data:params
    })
}

export function exportCheckWithdrawalApproval(params) {
    return request({
        url: 'agentApply/applyExportSync.do?',
        method: 'post',
        data:params
    })
}

export function rebateAgain(params) {
    return request({
        url: 'order/pinRebate/rebateAgain.do?',
        method: 'post',
        data:params
    })
}


export function pinRebateList(params) {
    return request({
        url: 'order/pinRebate/list.do?',
        method: 'post',
        data:params
    })
}

export function exportPinOrderRebate(params) {
    return request({
        url: '/order/pinRebate/export.do?',
        method: 'post',
        data:params
    })
}

// 退款列表
export function refundOrderList(params) {
    return request({
        url: 'orderRefund/getList.do?',
        method: 'post',
        data:params
    })
}


export function checkRefund(params) {
    return request({
        url: 'orderRefund/refundCheck.do?',
        method: 'post',
        data:params
    })
}
//增票资质列表
export function increBillList(params) {
    return request({
        url: 'billApply/increBillList.do?',
        method: 'post',
        data:params
    })
}
export function increBillCheck(params) {
    return request({
        url: 'billApply/increBillCheck.do?',
        method: 'post',
        data:params
    })
}


